import { message } from 'antd';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

/**
 * 将请求单例导出去(如果有自定义的需求就自己加)
 */
export const requestSingleton: { getInstance: (options?: AxiosRequestConfig) => AxiosInstance } = (function () {
  let instance: AxiosInstance;

  function init(options?: AxiosRequestConfig): AxiosInstance {
    const axiosInstance: AxiosInstance = axios.create({
      timeout: 1000 * 60 * 10,
      timeoutErrorMessage: '请求超时',
      withCredentials: true,
      // 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
      responseType: 'json',
      ...options,
    });

    axiosInstance.interceptors.request.use(
      (config) => {
        if (config.headers.Authorization) {
          return config;
        }
        const authorization = localStorage.getItem('Authorization');
        if (authorization) {
          config.headers.Authorization = authorization?.startsWith('Bearer') ? `${authorization}` : `Bearer ${authorization}`;
        } else {
          config.headers.Authorization = 'Basic Ym9xdWFuLW1hbmFnZW1lbnQ6MTIzNDU2';
        }
        return config;
      },
      (error: AxiosError) => {
        message.error(error?.message ?? '请求失败');
        return Promise.reject(error);
      },
    );

    axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
      message.error(error?.message ?? '请求失败');
      return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use((info: AxiosResponse) => {
      if (info.data && info.data.code === 200 && Object.prototype.hasOwnProperty.call(info.data, 'code')) {
        return Promise.resolve(info);
      }
      if (info.data && info.data.code === 401) {
        localStorage.removeItem('Authorization');
        let url = '/pbb-pc-anchorcircle/login/index'; //1:聘播播  2: 搭子  3: 播圈儿
        if (window?.top?.location.pathname === '/pbb-pc-anchorcircle/lugHome/index') {
          url = '/#/login';
        }
        window.top.location.href = url;
        return Promise.reject(info);
      }
      message.error(info.data.msg);
      return Promise.reject(info);
    }, undefined);

    return axiosInstance;
  }

  return {
    getInstance(options?: AxiosRequestConfig): AxiosInstance {
      if (!instance) {
        instance = init(options);
      }
      return instance;
    },
  };
})();

/**
 * 如果返回满足以status或者success字段区分成功，不需要再手动判断请求是否成功，和给出错误提示
 * 对axios的封装。https://github.com/axios/axios
 */
export function request<T = unknown>(options: AxiosRequestConfig = {}): Promise<T> {
  // 错误情况还需要处理的请自行处理。这里无法处理
  return requestSingleton
    .getInstance()
    .request<T>(options)
    .then((info) => info.data);
}

/**
 * 常见的后端数据返回结构。以泛型传递给request
 */
export interface BaseData<T = unknown> {
  status?: string;
  info?: string;
  data: T;
}

/**
 * 常见的后端分页的数据返回结构。以泛型传递给request
 */
export interface PaginationData<T = unknown> {
  status?: string;
  info?: string;
  success?: boolean;
  errorMsg?: string;
  errorCode?: number;
  data?: PureData<T>;
}

/**
 * 后端直接返回data对象
 */
export interface PureData<T = unknown> {
  calTotalPageCount: number;
  first: number;
  list: T[];
  page: number;
  pageSize: number;
  totalCount: number;
  totalPageCount: number;
}
