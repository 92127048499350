import { Dropdown, Menu, Modal, Input, Form, Button } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { getStaticResourceUrl } from '../../utils';
import { inject, observer } from 'mobx-react';
import type { MenuProps } from 'antd';
import React from 'react';

interface Opera {
  id: string;
  name: string;
  url?: string;
}

const defaultOperations: Opera[] = [
  {
    id: 'exit',
    name: '退出登录',
  },
];

@inject('layoutStore')
@observer
export class HeaderUserInfo extends React.Component<any> {
  public passwordFormRef = React.createRef<FormInstance>();

  public menu = () => {
    const { handleUserOpertion } = this.props.layoutStore;
    const list = [...this.props.userInfoRight, ...defaultOperations];

    const items: MenuProps['items'] = list.map((item) => ({
      key: item.id,
      label: <div>{item.url ? <a href={item.url}>{item.name}</a> : item.name}</div>,
    }));

    const onClick: MenuProps['onClick'] = (data) => {
      const clickItem = list.find((item) => item.id == data.key);
      handleUserOpertion(data, clickItem);
    };

    return {
      items,
      onClick,
    };
  };

  public passwordModal = () => {
    const { togglePassword, handleChangePassword } = this.props.layoutStore;
    return (
      <Modal
        maskClosable={false}
        onCancel={togglePassword.bind(this, false)}
        onOk={handleChangePassword.bind(this, this.passwordFormRef)}
        open
        title="修改密码"
      >
        <Form ref={this.passwordFormRef}>
          <Form.Item
            label="原密码"
            name="oldPassword"
            rules={[{ required: true }]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item
            label="新密码"
            name="newPassword"
            rules={[
              {
                required: true,
                min: 5,
                max: 50,
                message: '密码长度必须在5-50之间！',
              },
            ]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item
            label="确认新密码"
            name="confirmPassword"
            rules={[
              { required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('两次输入密码不一致');
                },
              }),
            ]}
          >
            <Input type="password" />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  private jumpToMembers = () => {
    const { homePageType } = this.props.layoutStore;
    window.open(`/egenie-clothes-union/members?type=${homePageType}`);
  };

  render() {
    const { styles } = this.props;
    const { userInfo, isJoinClothesUnion, showPassord, homePageTypes, homePageType, switchHomePageType, showBannerFlag } = this.props.layoutStore;
    const current = homePageTypes.find((item) => item.homePageType === homePageType);
    const imgName = homePageType === 1 ? 'switchArrowBlue.png' : 'switchArrow.png';
    return (
      <div id={styles.headerUser}>
        {/* 扩展  */}
        {this.props.userInfoLeft}

        <Dropdown
          className={styles.name}
          menu={this.menu()}
          placement="bottomLeft"
        >
          <span
            style={{
              fontSize: '18px',
              fontWeight: 500,
            }}
          >
            {userInfo.nickName || userInfo.userName}
          </span>
        </Dropdown>

        {homePageTypes.length > 1 && (
          <>
            <span className={styles.splitIcon}>|</span>
            <span
              className={homePageType === 1 ? `${styles.switchRoleWrapper} ${styles.bgBlue}` : styles.switchRoleWrapper}
              onClick={switchHomePageType}
            >
              <span className={styles.switchRole}>
                {current ? current.indexUserName : ''}
                <img
                  alt="加载失败"
                  src={getStaticResourceUrl(`pc/ts/egenie-common/images/${imgName}`)}
                />
              </span>
            </span>
          </>
        )}
        {showPassord ? this.passwordModal() : null}
      </div>
    );
  }
}
